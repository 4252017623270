import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Obituario from './Pages/Obituario'
import Servicos from './Pages/Servicos'
import Categoria from './Pages/Categoria'

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to="/obituario" />} />
        <Route path='/obituario' element={<Obituario />} />
        <Route path='/servicos' element={<Servicos />} />
        <Route path='/fidelidade' element={<Categoria />} />
      </Routes>
    </Router>
  )
}

export default App
