import { api } from '../api.js'
import { useState, useEffect, useContext, React } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import '../Styles/Obituario.css'
import '../Styles/Grid.css'
import Pagination from '../Components/Pagination.js'

function Obituario(){
    const [linhas, setLinhas] = useState([])
    const [pagesCount, setPagesCount] = useState()
    const [currentPage, setCurrentPage] = useState()
    const [searchState, setSearchState] = useState("")

    const navigate = useNavigate()
    const location = useLocation()

    const useQuery = () => new URLSearchParams(location.search)
    const query = useQuery()
    let page = query.get('page')
    let search = query.get('search')
    if(search === null) search = ''
    if(page === null) page = 1

    useEffect(() => {
        api.get('/pegasus/getObituario', { params: { page: page, search: search } })
        .then(res => {
            setPagesCount(res.data[0].paginas)
            setLinhas(res.data[0].linhas)
            switchLoader(0)
        })
    }, [page, search])

    async function setFiltro(value, type){
        switchLoader(1)

        let queryParams = new URLSearchParams(window.location.search)
        const previousQueryParams = queryParams.toString()

        if(search !== '') queryParams.append('search', search)
        
        if(type === 'page' && value >= 1 && value <= pagesCount){
            queryParams.set('page', value)
        }

        if(type === 'search'){
            if(value){
                queryParams.set('search', value)
            } else {
                queryParams.delete('search')
            }
        }

        let queryString = queryParams.toString()

        if(queryString !== previousQueryParams){
            if(queryString){
                navigate(`/obituario?${queryString}`)
            } else {
                navigate('/obituario')
            }
        } else {
            switchLoader(0)
        }
    }

    async function handleKeyPress(e){
        if(e.key === 'Enter') {
            setFiltro(searchState, 'search')
        }
    }

    async function switchLoader(n){
        let loader = document.getElementById('wrapper')
        n === 0 ? loader.style.display = 'none' : loader.style.display = 'flex'
    }

    return (
        <div className='page prevent-select'>
            <div className='page-content'>
                <div id="wrapper" style={{ display: 'flex' }}>
                    <div id="loader"></div>
                </div>
                {/* <div className='header-div'>
                    <div className='logo-div'>
                        <img src='logo_luto.png' width='80px' height='auto'></img>
                    </div>

                    <div className='search-div'>
                        <div className='input-container'>
                            <input type='text' placeholder='Nome do falecido' className='search-input' onChange={(e) => setSearchState(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                        </div>
                        <div className='button-container'>
                            <button type='submit' className='search-button' onClick={() => setFiltro(searchState, 'search')}>Buscar</button>
                        </div>
                    </div>
                </div> */}

                <div className='top-div'>
                    <div className='top-div-left'>
                        <div className='logo-div'>
                            <img src='logo_luto.png' id='logo-luto'></img>
                        </div>
                    </div>
                    <div className='top-div-right'>
                        <div className='title-div'>
                            <h1>OBITUÁRIO</h1>
                        </div>

                        <div className='search-div'>
                            <div className='input-container'>
                                <input type='text' placeholder='Nome do falecido' className='search-input' onChange={(e) => setSearchState(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                            </div>
                            <div className='button-container'>
                                <button type='submit' className='search-button' onClick={() => setFiltro(searchState, 'search')}>Buscar</button>
                            </div> 
                            <div className='mag-container' onClick={() => setFiltro(searchState, 'search')}>
                                <img className='mag-svg svg-settings-white' src='icon-magnifying.svg' width='20px' height='auto' ></img>
                            </div>
                        </div>
                    </div>
                </div>

                <Pagination pagesCount={pagesCount} currentPage={parseInt(page)} switchLoader={switchLoader}></Pagination>

                {pagesCount !== 0 ? (
                    <div className='grid-container'>
                        <p className='p-warn'>* O horário poderá ser alterado à pedido do declarante</p>
                        {linhas.map((linha, i) => (
                            <div className='grid-item' key={i}>
                                <div className='item-column'>
                                    <div className='item-title'>
                                        <p className='title'>{linha.nome_falecido}</p>
                                    </div>
                                    <div className='item-info'>
                                        <div className='info-div'>
                                            <img src='icon-star.svg' width='20px' height='auto' alt='nascimento' className='svg-settings'></img>
                                            <p className='info' style={{ paddingLeft: '6px' }}>{linha.data_nascimento}</p>
                                        </div>
                                        <div className='info-div'>
                                            <img src='icon-cross.svg' width='18px' height='auto' alt='falecimento' className='svg-settings'></img>
                                            <p className='info'>{linha.data_falecimento}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='item-column'>
                                    <div className='item-title'>
                                        <p className='title' style={{ color: '#3B66A7' }}>Velório</p>
                                    </div>
                                    <div className='item-info'>
                                        <div className='info-div'>
                                            <img src='icon-church.svg' width='20px' height='auto' alt='local de velório' className='svg-settings'></img>
                                            <p className='info'>{linha.local_velorio}</p>
                                        </div>
                                        <div className='info-div'>
                                            <img src='icon-calendar.svg' width='20px' height='auto' alt='data do velório' className='svg-settings'></img>
                                            <p className='info'>{linha.data_velorio}</p>
                                        </div>
                                        <div className='info-div'>
                                            <img src='icon-clock.svg' width='20px' height='auto' alt='hora do velório' className='svg-settings'></img>
                                            <p className='info'>{linha.hora_velorio}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='item-column'>
                                    <div className='item-title'>
                                        <p className='title' style={{ color: '#3B66A7' }}>Sepultamento/Cremação</p>
                                    </div>
                                    <div className='item-info'>
                                        <div className='info-div'>
                                            <img src='icon-tombstone.svg' width='20px' height='auto' alt='local de sepultamento/cremação' className='svg-settings'></img>
                                            <p className='info'>{linha.local_sepultamento}</p>
                                        </div>
                                        <div className='info-div'>
                                            <img src='icon-calendar.svg' width='20px' height='auto' alt='data do sepultamento/cremação' className='svg-settings'></img>
                                            <p className='info'>{linha.data_sepultamento}</p>
                                        </div>
                                        <div className='info-div'>
                                            <img src='icon-clock.svg' width='20px' height='auto' alt='hora do sepultamento/cremação' className='svg-settings'></img>
                                            <p className='info'>{linha.hora_sepultamento}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='no-result-div'>
                        <h2>Nenhum resultado encontrado.</h2>
                    </div>
                )}

                <Pagination pagesCount={pagesCount} currentPage={parseInt(page)} switchLoader={switchLoader}></Pagination>
            </div>

            <div className='footer'>
                <p>Luto Curitiba 2024. Todos os direitos reservados.</p>
            </div>
        </div>
    )
}

export default Obituario