import { useState, useEffect, React } from "react"
import socketIOClient from 'socket.io-client'
import '../Styles/Servicos.css'
import '../Styles/Login.css'
import { api } from '../api.js'
import env from '../env.js'

const ENDPOINT = env === 'production' ? `https://obituario.lutocuritiba.com.br/` : `http://localhost:8080/`

function Servicos() {
    const [servicos, setServicos] = useState([])
    const [sepultados, setSepultados] = useState([])
    const [countServicos, setCountServicos] = useState()
    const [user, setUser] = useState(null)

    useEffect(() => {
        api.get('/auth/user')
        .then(response => {
            setUser(response.data)

            const socket = socketIOClient(ENDPOINT)
            socket.on('FromAPI', data => {
                const sepultar = data.filter(i => i.finalizado === 0)
                const sepultado = data.filter(i => i.finalizado === 1)
                setServicos(sepultar)
                setSepultados(sepultado)
                setCountServicos(data.length)
                switchLoader(0)
            })
            return () => {
                socket.disconnect()
            }
        })
        .catch(error => {
            console.error('Error fetching user data:', error)
        })
    }, [])

    function handleLogin(){
        window.open(`${ENDPOINT}auth/google`, '_self')
    }

    function handleLogout() {
        window.open(`${ENDPOINT}auth/logout`, '_self')
    }

    function switchLoader(n){
        let loader = document.getElementById('wrapper')
        n === 0 ? loader.style.display = 'none' : loader.style.display = 'flex'
    }

    if (!user) {
        return (
            <div className="profile-page">
                <div className="profile-content">
                    <div className="profile-login-card">
                        <div className="luto-icon" style={{ textAlign: 'center' }}>
                            <img alt="icon luto" src="icon-luto.png" width='200px' height='auto'></img>
                        </div>
                        <div className="profile-login-card-top" style={{ marginTop: '20px' }}>
                            <h1>Painel de Serviços</h1>
                        </div>
                        <div className="profile-login-card-buttons">
                            <div className="profile-login-buttons" style={{ width: '400px' }} onClick={() => handleLogin()}>
                                <img alt="google logo" src="google-logo.svg" width='45px' height='auto'></img>
                                <p style={{ textAlign: 'center', marginLeft: '5px' }}>Entrar com Google</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className='page prevent-select'>
            <div id="wrapper" style={{ display: 'flex' }}>
                <div id="loader"></div>
            </div>

            <div className='page-content'>
                <div className="sv-title-div">
                    <div style={{ width: '33%', textAlign: 'left' }}>
                        <img alt="icon luto" src="icon-luto.png" width='40px' height='40px' style={{ marginLeft: '1vw' }}></img>
                    </div>
                    <h1 style={{ width: '34%' }}>Serviços em andamento</h1>
                    <div style={{ width: '33%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <div onClick={() => handleLogout()} className="logout-bnt">Sair</div>
                    </div>
                </div>

                {countServicos !== 0 ? (
                    <div>
                        <h3 style={{ margin: '15px 1vw', fontFamily: 'Roboto Regular', fontSize: '20px' }}>A sepultar:</h3>
                        <div className="sv-grid-container">
                            {servicos.map((servico, i) => (
                                <div className="sv-item" key={i} style={{ boxShadow: servico.finalizado === 0 ? "0 0 6px #3B66A7" : "0 0 10px rgba(0, 0, 0, 0.1)",
                                backgroundColor: servico.finalizado === 0 ? '#daeaf1' : '#e6edf0' }}>
                                    <div className="sv-top-line" style={{ color: servico.finalizado === 0 ? "#3B66A7" : "#54595F" }}>
                                        <div className="sv-line">
                                            <p className="sv-title">{servico.nome_falecido}</p>
                                        </div>
                                        <div className="sv-line">
                                            <p className="sv-title" style={{ minWidth: '20px' }}>CTO: {servico.contrato}</p>
                                        </div>
                                    </div>
                                    <div className="sv-line" style={{ marginTop: '-2px', marginBottom: '10px' }}>
                                        <p className="sv-title" style={{ color: '#3B66A7', fontSize: '13px' , fontFamily: 'Roboto Regular'}}>CATEGORIA DO PLANO:</p>
                                        {typeof servico.categoria !== 'undefined' ? (
                                            <p style={{ fontFamily: 'Roboto Medium', padding: '1px 30px', fontSize: '13px',
                                                backgroundColor: servico.categoria === 'GOLD' ? '#ffc000' 
                                                    : servico.categoria === 'PLATINUM' ? '#d8d8d8' 
                                                    : servico.categoria === 'BLACK' ? 'black'
                                                    : servico.categoria === 'SAFIRA' ? '#0070c0' : 'none',
                                                color: servico.categoria === 'GOLD' ? 'black' 
                                                    : servico.categoria === 'PLATINUM' ? 'black' 
                                                    : servico.categoria === 'BLACK' ? 'white'
                                                    : servico.categoria === 'SAFIRA' ? 'white' : 'none'
                                            }} className="sv-info">{servico.categoria}</p>
                                        ) : (
                                            <p style={{ fontFamily: 'Roboto Medium', padding: '1px 30px', fontSize: '13px', backgroundColor: 'none', color: 'none'}} 
                                            className="sv-info">CLIENTE SEM CATEGORIA</p>
                                        )}
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Velório:</p>
                                        <p className="sv-info" style={{ display: 'inline-block' }}>{servico.local_velorio}
                                            <img alt="icon yellow star" src="icon-ystar.svg" width='14px' height='auto' 
                                            style={{ display: servico.memorial === 1 ? "inline-block" : "none", marginLeft: '5px' }}></img>
                                        </p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Hora Vel:</p>
                                        <p className="sv-info">{servico.hora_velorio}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Cemitério:</p>
                                        <p className="sv-info">{servico.local_sepultamento}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Hora Sep:</p>
                                        <p className="sv-info">{servico.data_hora_sepultamento}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Religião:</p>
                                        <p className="sv-info">{servico.religiao}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Funerária:</p>
                                        <p className="sv-info">{servico.funeraria}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Declarante:</p>
                                        <p className="sv-info">{servico.declarante}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Obs:</p>
                                        <p className="sv-info" style={{ color: "#54595F" }}>{servico.observacao}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <h3 style={{ margin: '15px 1vw', fontFamily: 'Roboto Regular', fontSize: '20px' }}>Sepultados:</h3>
                        <div className="sv-grid-container">
                            {sepultados.map((servico, i) => (
                                <div className="sv-item" key={i} style={{ boxShadow: servico.finalizado === 0 ? "0 0 6px #3B66A7" : "0 0 10px rgba(0, 0, 0, 0.1)",
                                backgroundColor: servico.finalizado === 0 ? '#daeaf1' : '#e6edf0' }}>
                                    <div className="sv-top-line" style={{ color: servico.finalizado === 0 ? "#3B66A7" : "#54595F" }}>
                                        <div className="sv-line">
                                            <p className="sv-title">{servico.nome_falecido}</p>
                                        </div>
                                        <div className="sv-line">
                                            <p className="sv-title" style={{ minWidth: '20px' }}>CTO: {servico.contrato}</p>
                                        </div>
                                    </div>
                                    <div className="sv-line" style={{ marginTop: '-2px', marginBottom: '10px' }}>
                                        <p className="sv-title" style={{ color: '#3B66A7', fontSize: '13px' , fontFamily: 'Roboto Regular'}}>CATEGORIA DO PLANO:</p>
                                        {typeof servico.categoria !== 'undefined' ? (
                                            <p style={{ fontFamily: 'Roboto Medium', padding: '1px 30px', fontSize: '13px',
                                                backgroundColor: servico.categoria === 'GOLD' ? '#ffc000' 
                                                    : servico.categoria === 'PLATINUM' ? '#d8d8d8' 
                                                    : servico.categoria === 'BLACK' ? 'black'
                                                    : servico.categoria === 'SAFIRA' ? '#0070c0' : 'none',
                                                color: servico.categoria === 'GOLD' ? 'black' 
                                                    : servico.categoria === 'PLATINUM' ? 'black' 
                                                    : servico.categoria === 'BLACK' ? 'white'
                                                    : servico.categoria === 'SAFIRA' ? 'white' : 'none'
                                            }} className="sv-info">{servico.categoria}</p>
                                        ) : (
                                            <p style={{ fontFamily: 'Roboto Medium', padding: '1px 30px', fontSize: '13px', backgroundColor: 'none', color: 'none'}} 
                                            className="sv-info">CLIENTE SEM CATEGORIA</p>
                                        )}
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Velório:</p>
                                        <p className="sv-info" style={{ display: 'inline-block' }}>{servico.local_velorio}
                                            <img alt="icon yellow star" src="icon-ystar.svg" width='14px' height='auto' 
                                            style={{ display: servico.memorial === 1 ? "inline-block" : "none", marginLeft: '5px' }}></img>
                                        </p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Hora Vel:</p>
                                        <p className="sv-info">{servico.hora_velorio}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Cemitério:</p>
                                        <p className="sv-info">{servico.local_sepultamento}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Hora Sep:</p>
                                        <p className="sv-info">{servico.data_hora_sepultamento}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Religião:</p>
                                        <p className="sv-info">{servico.religiao}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Funerária:</p>
                                        <p className="sv-info">{servico.funeraria}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Declarante:</p>
                                        <p className="sv-info">{servico.declarante}</p>
                                    </div>
                                    <div className="sv-line">
                                        <p className="sv-title">Obs:</p>
                                        <p className="sv-info" style={{ color: "#54595F" }}>{servico.observacao}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                ) : (
                    <div className='no-result-div'>
                        <h2>Nenhum serviço encontrado.</h2>
                    </div>
                )}
            </div>

            <div className='footer'>
                <p>Luto Curitiba 2024. Todos os direitos reservados.</p>
            </div>
        </div>
    )
}

export default Servicos